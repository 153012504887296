@import "../../global.scss";

.bgMain{
    // background-image: url("https://images.pexels.com/photos/618833/pexels-photo-618833.jpeg?cs=srgb&dl=pexels-sagui-andrea-618833.jpg&fm=jpg");
    background-color: #F6F8FA;
    
}

.nav__topBar{
    @include flexCenterSBDirection(row);   
    width: 100%;
    height: 84px;
    margin: auto;
    
    padding: 0 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    z-index: 3;

    background: rgba( 255, 255, 255, 0.2 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    color: $black-color;
    // background-color: #F6F8FA;
    // border-bottom: 1px solid #dee2e6!important;
}

.nav__company{
    font: 500 20px/32px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    color: #000;
    white-space: nowrap;
    flex: 1 1 auto;
    margin-left: 20px;
}


.content{
    @include flexCenterSBDirection(row);   

}

// --------------------- sideBar-------------------
.sideBar{
    color: $black-color;
    // background-color: #F6F8FA;

    height: 100%;
    min-height: 100vh ;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    flex-direction: column;
    justify-content:left;

    outline: 0;
    box-sizing: border-box;
    overflow-y: auto;
    width: 240px;
    min-width: 240px;
    z-index: 2;
    margin-top: 84px;
    margin-bottom: auto;
    padding-bottom: 20px;
    transition: width 0.2s;
    visibility: visible;
    font-weight: 700;

    background: rgba( 255, 255, 255, 0.2 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}

.sideBar__collapsed{
    width: 100px;
    min-width: 100px;
}

.sideBar__item{
    padding: 14px 7px 14px 7px;
    max-height: 48px;
    font-size: map-get($font-size, sm );
    cursor: pointer;
    font-weight: 500;
    color: #636363;
}

.sideBar__item-selected{
    padding: 14px 7px 14px 7px;
    max-height: 48px;
    font-size: map-get($font-size, sm );
    cursor: pointer;

    background-color: $primary-color;
    border-radius: 12px;
    // box-shadow: 1px 1px 2px 1px rgba(0,0,0,.2);
    width: 100%;

    border-style: solid;
    border-width: 0px;
    border-color: $primary-color;
    color: #fff;
    font-weight: 700;
}

.sideBar__item:hover{
    background-color: #e4e4e4;
    border-radius: 12px;
}


// ------------------- menu icon (left of userName) ----------------
.menuItem{
    width: 80px;
    height: 80px;
    padding: 10px 10px 5px 10px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s;
    border-radius: 15px;
    font-weight: map-get($font-weight, medium );

    // background-color: rgba(255, 71, 71, 0.205);
}

.menuItem:hover{
    background-color: #f1f1f1;
    // background-color: rgba(255, 71, 71, 0.205);
    // background-color: #e4e4e4;
}

.menuItem__disabled{
    width: 80px;
    height: 80px;
    padding: 10px 10px 5px 10px;
    margin: 5px;
    cursor: auto;
    text-align: center;
    transition: background-color 0.2s;
    border-radius: 15px;
    font-weight: map-get($font-weight, medium );
    background-color: #e4e4e4;
}


// ------------------- administration menuItems--------------------
.adminMenuItem{
    color : $gray-color;
    padding: 14px 0px 14px 14px;
    max-height: 48px;
    font-size: map-get($font-size, sm );
    cursor: pointer;
}

.adminMenuItem-selected{
    color: $black-color;
    padding: 14px 0px 14px 14px;
    max-height: 48px;
    font-size: map-get($font-size, sm );
    font-weight: map-get($font-weight, bold);
    cursor: pointer;

    background-color: #f1f1f1;
}

// ------------------- custome icons --------------------
.customIcon{
    width: 35px;
    margin-bottom: 10px;
}

.dropDown{
    background: rgba( 255, 255, 255, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 1px );
}

.rightPanel{
    max-width: calc(100% - 250px);
    background: rgba( 255, 255, 255, 0.2 );
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
}