*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// ---------------- Scroll Bar ----------------
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 122, 122);
}


// ---------------- input type=[number] ----------
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


// ---------------- toastify styles ----------------
.Toastify__toast-container {
  width: fit-content;
  min-width: 400px;
}

.Toastify__toast {
  background-color: white;
  color: black;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  min-height: 42px;
}

.Toastify__toast-body {
  height: 26px;
  margin: 0px;
  padding: 0px;
}