@import "../../global.scss";

.footer{
    display: flex;
    flex-direction: column;
    align-items: center;


    color: white;

    background-color: #000;
    padding: 4rem;
}

.footer__content{
    width: 1110px;
    min-width: 200px;
    margin: auto;
    
    place-content: flex-start space-around;
    align-items: flex-start;
    flex-flow: row wrap;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 40px;
}

.footer__col1{
    flex: 1 1 0%;
}

.footer__col2{
    flex: 1 1 25%;
    max-width: 25%;
}

.footer__col3{
    flex: 1 1 25%;
    max-width: 25%;
}

.footer__col4{
    flex: 1 1 30%;
    max-width: 30%;
}

.footer__col5{
    flex: 1 1 100%;
    max-width: 100%;
}

@media screen and (max-width: 1175px) {
    .footer__content{
        flex: 1 1 100%;
        max-width: 100%;
    }
    .footer__col1{
        flex: 1 1 100%;
        max-width: 100%;
    }
    
    .footer__col2{
        flex: 1 1 100%;
        max-width: 100%;
    }
    
    .footer__col3{
        flex: 1 1 100%;
        max-width: 100%;
    }
    
    .footer__col4{
        flex: 1 1 100%;
        max-width: 100%;
    }
    
    .footer__col5{
        flex: 1 1 100%;
        max-width: 100%;
    }
}



.li{
    color: white
}

.h4{
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 18px;
}

.h5{
    font-weight: 300;
    font-size: 14px;
    margin: 14px 0px 14px 0px;
    color: $white-color;
    text-decoration-color: #000;
}

.h5:hover{
    text-decoration: underline;
}

a{
  text-decoration: none;  
}

.textWidget{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
}

.list-vrt{
    font: 400 14px/20px Roboto,Helvetica Neue,sans-serif;
    letter-spacing: normal;
    text-decoration: none;
    list-style: none;
    margin: 0!important;
    padding-left: 0!important;
}

li {
  display: inline-block;
  margin-left: 0;
  padding: 5px;
  // border-bottom: 2px solid #eee;
  transition: all 0.5s;
  font-family: Lato, sans-serif;
  font-weight: 300;
  cursor: pointer;
  color: black;
}

li.selected {
  border-bottom: 5px solid #337ab7;
  color: #444;
}


// ------------------- a link -------------------------

