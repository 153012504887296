@import "../../global.scss";

.home__content{
  margin: 20px; 
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  background: rgba(255, 255, 255, 0.86);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(13.7px);
  -webkit-backdrop-filter: blur(13.7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.home__row1{
  min-height: 60px;
  padding: 12px 15px 0px 15px;
  font-size: map-get($font-size, md );
}

.home__row2{
  font-size: map-get($font-size, md );
}
